<template>
    <div :style="raffleCardStyle" class="raffle card rounded-md border p-4 flex flex-col justify-center">
      <h2 class="text-lg text-center py-5 raffle-title">{{ title }}</h2>
      <h3 class="text-lg raffle-description text-center font-medium" v-html="description"></h3>
      <p class="text-center font-medium" v-html="stepsDescription"></p>
      <div class="my-4">
        <ul class="text-left px-12 my-4">
          <li v-for="(step, index) in steps" :key="index">
            <p class="text-sm" v-html="step"></p>
          </li>
        </ul>
      </div>
  
      <button @click="linkTwitterAccount" :disabled="twitterUser !== null"
        class="w-full mt-10 bg-blue-300 text-black px-6 py-2 rounded font-bold hover:bg-blue-400 transition duration-300 text-md h-12 disabled:opacity-50 disabled:cursor-not-allowed">
        <span v-if="twitterUser === null">Connect Twitter</span>
        <span v-if="twitterUser !== null">{{ twitterUser }}</span>
      </button>
  
      <div class="grid grid-cols-12 gap-4 my-20 w-full items-center">
        <div class="col-span-4">
          <button @click="enterRaffle('launch')"
            class="bg-orange-400 text-white px-6 py-2 h-12 rounded font-bold hover:bg-orange-500 transition duration-300 text-md">
            Join raffle
          </button>
        </div>
        <div class="col-span-8">
          <input id="raffle-tweet" type="text"
            v-model="enterRaffleTweet" :placeholder="raffleTweetPlaceholder"
            class="w-full border-2 border-black px-4 py-3 h-12 text-base text-center" />
        </div>
      </div>
  
      <p v-if="errorMessageEnterRaffle" class="text-center text-red-500">{{ errorMessageEnterRaffle }}</p>
      <p v-if="enteredRaffle" class="text-center text-green-500">You have joined the raffle. Check our socials for the raffle results. Gl ser!</p>
    </div>
  </template>
  
  <script>
  import User from '../models/User';

  export default {
    props: {
      title: {
        type: String,
        default: 'Join the Beracoin lottery'
      },
      description: {
        type: String,
        default: 'We will be doing weekly raffles of NFTs, WL and more'
      },
      stepsDescription: {
        type: String,
        default: 'Follow the steps below to join'
      },
      steps: {
        type: Array,
        default: () => [
          '1. Tweet a screenshot of your Bera Passport.',
          '2. Connect your twitter account.',
          '3. Paste the link to your tweet below.',
          '4. Hold at least 10k $BERA tokens. You need to keep holding until the raffle date.',
          '5. Click join.'
        ]
      },
      twitterUser: {
        type: String,
        default: null
      },
      raffleId: {
        type: String,
        default: 'launch'
      },
      raffleTweetPlaceholder: {
        type: String,
        default: 'Paste your tweet link here...'
      },
      backgroundUrl: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        enterRaffleTweet: '',
        errorMessageEnterRaffle: '',
        enteredRaffle: false,
      };
    },
    methods: {
        linkTwitterAccount()
        {
            window.location.href = `${process.env.VUE_APP_API_URL}/v1/oauth/twitter?accessToken=${localStorage.getItem('accessToken')}`;
        },
        async enterRaffle() {
            if (this.twitterUser == null) {
                this.errorMessageEnterRaffle.value = "Ser can you read? You need to connect your twitter account";
                return;
            }
            try {
                const userInstance = new User();
                await userInstance.enterRaffle(this.raffleId, this.enterRaffleTweet);
                this.enteredRaffle = true;
                this.errorMessageEnterRaffle = "";
            } catch (error) {
                this.errorMessageEnterRaffle = error.message;
                this.enteredRaffle = false;
            }
        },
        async getRaffleEntry()
        {
            const raffleEntries = await new User().getRaffleEntry();
            if (raffleEntries != null && raffleEntries[this.raffleId]) {
                this.enteredRaffle = true;
                this.enterRaffleTweet = raffleEntries[this.raffleId];
            } 
            
        }
    },
    created: function(){
        this.getRaffleEntry()
    },
    mounted() {
        console.log("mounted"); // Should log the passed value
    },
    computed: {
        raffleCardStyle() {
            return this.backgroundUrl
            ? {
                backgroundImage: `url(${this.backgroundUrl})`,
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                backgroundBlendMode: 'lighten',
                backgroundSize: 'contain'
                }
            : {};
        }
    }
  };
  </script>
  
  <style scoped>
    .card {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .raffle-title {
        font-weight: 700;
    }
    
  </style>
  